import request from '../Axios'

// 列表
export const historyPage = data => {
  return request({
    url: '/external-coupon/page',
    method: 'post',
    data
  })
}

// 刷新优惠券缓存
export const couponRefresh = data => {
  return request({
    url: '/external-coupon/refresh',
    method: 'get'
  })
}

// 根据关键词筛选优惠券列表
export const couponList = params => {
  return request({
    url: `/external-coupon/list?keyword=${params.keyword || ''}`,
    method: 'get'
  })
}

// 优惠券发放
export const couponSend = data => {
  return request({
    url: '/external-coupon/send',
    method: 'post',
    data
  })
}
