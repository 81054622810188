<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="grantcard">发放卡券</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #couponType>
        <el-table-column label="卡券类型" align="center">
          <template #default="scoped">
            {{ scoped.row.couponType == 1 ? '优惠码' : '优惠券' }}
          </template>
        </el-table-column>
      </template>
      <template #validStartTime>
        <el-table-column label="卡券有效期" width="160" align="center">
          <template #default="scoped">
            <span v-if="scoped.row.validStartTime">{{
              scoped.row.validStartTime + scoped.row.validEndTime
            }}</span>
          </template>
        </el-table-column>
      </template>
      <template #sendStatus>
        <el-table-column label="发放状态" align="center">
          <template #default="scoped">
            {{ scoped.row.sendStatus ? '发放成功' : '发放失败' }}
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="labelVisible"
      title="发放卡券"
      width="40%"
      class="user_dialog"
      :before-close="labelClose"
    >
      <div class="writeoff_box">
        <span>卡券名称：</span>
        <el-select
          v-model="froms.couponId"
          filterable
          remote
          placeholder="可按名称模糊匹配"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in couponOptions"
            :key="item.groupId"
            :label="item.title"
            :value="item.groupId"
          />
        </el-select>
        <!-- <el-button link type="primary" style="margin-left: 20px;" @click="refresh">同步有赞最新卡券数据</el-button> -->
      </div>
      <div class="writeoff_box remark_box">
        <span>按手机号发放：</span>
        <el-input
          type="textarea"
          v-model="froms.mobileList"
          placeholder="若有多个手机号，请换行填写"
          class="dialog_input"
          :rows="4"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span></span>
        <el-text type="primary" style="width: auto"
          >或（若有上传Excel，将忽略文本框内容填写的手机号）</el-text
        >
      </div>
      <div class="writeoff_box remark_box">
        <span>按Excel名单发放：</span>
        <el-upload
          class="upload-demo"
          :limit="1"
          :auto-upload="false"
          accept=".xlsx,.xls"
          :action="UploadUrl()"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-remove="handleRemove"
          :file-list="froms.file"
        >
          <el-button type="primary">上传</el-button>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="uploadFile"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage } from 'element-plus'
import {
  historyPage,
  couponRefresh,
  couponList,
  couponSend
} from '@/api/cardManagement/youzan'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入关键字以搜索'
    },
    {
      label: 'status',
      title: '发放状态',
      placeholder: '请选择',
      type: 'options',
      options: [
        {
          label: '发放成功',
          value: true
        },
        {
          label: '发放失败',
          value: false
        }
      ]
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'title', label: '卡券名称' },
    { slot: 'couponType' },
    { prop: 'description', label: '卡券内容', width: '420' },
    { prop: 'mobile', label: '发放顾客手机号' },
    { prop: 'sentAt', label: '发放时间' },
    { slot: 'validStartTime' },
    { slot: 'sendStatus' }
  ],
  labelVisible: false,
  froms: {
    couponId: '',
    file: [],
    mobileList: ''
  },
  couponOptions: []
})

onMounted(() => {
  getDataList()
})

// 发放卡券弹窗
const grantcard = () => {
  froms.value = {
    couponId: '',
    file: [],
    mobileList: ''
  }
  labelVisible.value = true
}

// 弹窗关闭
const labelClose = () => {
  froms.value = {
    couponId: '',
    file: [],
    mobileList: ''
  }
  labelVisible.value = false
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.sendStatus = fromData.value.status
  }
  historyPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 同步有赞最新卡券数据
const refresh = () => {
  couponRefresh().then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 可按名称模糊匹配
const remoteMethod = query => {
  if (query) {
    couponList({ keyword: query }).then(({ data: res }) => {
      if (res.code == 200) {
        couponOptions.value = res.data
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    couponOptions.value = []
  }
}

// 文件超出个数限制时的钩子
const exceedFile = (files, fileList) => {
  ElMessage.warning(
    `只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`
  )
}

// 文件状态改变时的钩子
const fileChange = file => {
  froms.value.file.push(file.raw)
}

const handleRemove = () => {
  froms.value.file = []
}

const UploadUrl = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 弹窗提交
const uploadFile = () => {
  if (froms.value.couponId == '') {
    ElMessage.warning('请填写卡券名称')
    return false
  }
  if (froms.value.file.length == 0 && froms.value.mobileList == '') {
    ElMessage.warning('请填写手机号或者上传名单')
    return false
  }
  let form = new FormData()
  form.append('couponId', froms.value.couponId)
  if (froms.value.file.length > 0) {
    form.append('file', froms.value.file[0])
  }
  if (froms.value.mobileList != '') {
    form.append('mobileList', froms.value.mobileList.split('\n'))
  }
  couponSend(form).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      froms.value.file = []
      labelVisible.value = false
      getDataList()
    } else {
      ElMessage.warning(res.msg)
    }
  })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  labelVisible,
  froms,
  couponOptions
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 30px;

  span {
    width: 120px;
    text-align: right;
  }

  .dialog_input {
    width: 50%;
  }

  i {
    color: red;
  }
}

.remark_box {
  align-items: flex-start;
}
</style>
